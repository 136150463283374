<template>
  <div>

    <section class="section">
      <div class="container has-text-centered">
        <h1 class="title is-3 is-marginless">{{ $t('Potential issues with accessing the Samruk-Kazyna e-procurement portal') }}</h1>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <div class="content">
          <h3>1. <router-link to="keycheck">{{ $t('Please make sure that software necessary for using security token was installed properly') }}</router-link></h3>
          <h3>2. {{ $t('Please make sure that the USB port of your computer is connected with only one security token and') }}
            <a><router-link :to="{path: '/instructions', name: 'instructions', params: {step: 'SmartCard'}}">{{ $t('Smart Card Service') }}</router-link></a> {{ $t('is running') }}</h3>
<!--          <h3>3. {{ $t('Check for certificates in the repository') }}</h3>-->
<!--          <p>{{ $t('Perform the following operations') }}:</p>-->
<!--          <ol>-->
<!--            <li>{{ $t('Go to the “Start menu”') }};</li>-->
<!--            <li>{{ $t('In the search field, type “certmgr.msc” and open it') }};</li>-->
<!--            <li>{{ $t('Open “Personal” - “Certificates” folder. Check the certificate holder’s name issued by Gamma Technologies Primary CA') }};</li>-->
<!--            <li>{{ $t('Open “Trusted Root Certification Authorities” - “Certificates” folder. Check for two Gamma Technologies Certificate Authority certificates') }};</li>-->
<!--            <li>{{ $t('Open “Intermediate Certification Authorities” - “Certificates” folder. Check for two Gamma Technologies Primary CA certificates') }};</li>-->
<!--            <li>{{ $t('If certificates are not installed, it should be installed using') }}-->
<!--              <a href="/application/distr/InstallCA_rus.exe">{{ $t('PKI Root Certificate Installation Wizard 1') }}</a> {{ $t('or') }}-->
<!--              <router-link :to="{path: '/instructions', name: 'instructions', params: {step: 'IIW'}}">{{ $t('manually') }}</router-link>-->
<!--              {{ $t('Restart your PC and try to log in again') }}.-->
<!--            </li>-->
<!--          </ol>-->
          <h3>3. {{ $t('Check if CryptoSocket software is installed and running on your computer') }}</h3>

          <div v-if="isSocket && isSocketKey">
            <div class="columns">
              <div class="column is-8">
                <div class="columns">
                  <div class="column is-8">
                    <p>{{ $t('”CryptoSocket” software is installed and running') }}</p>
                  </div>
                  <div class="column is-1">
                    <img :src="require('../assets/icons/check_green.svg')" width="32">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else-if="isSocket && !isSocketKey">
            <div class="columns">
              <div class="column is-8">
                <div class="columns">
                  <div class="column is-8">
                    <p>{{ $t('Please install the correct version of the ”CryptoSocket” software') }}</p>
                  </div>
                  <div class="column is-1">
                    <img :src="require('../assets/icons/error.svg')" width="32">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="columns">
              <div class="column is-8">
                <div class="columns">
                  <div class="column is-8">
                    <p>{{ $t('Please make sure the ”CryptoSocket” software is running') }}
                       <template v-html="$t('Please install the software')"></template>
                       <a v-bind:href="'/application/distr/SetupCryptoSocket_' + lang + '.zip?v=1'">CryptoSocket</a> {{ $t('if it is not installed on your PC') }}</p>
                  </div>
                  <div class="column is-1">
                    <img :src="require('../assets/icons/error.svg')" width="32">
                  </div>
                </div>
              </div>
            </div>
          </div>

          <h3>4. {{ $t('Disable the “SSL 2.0” option in the browser') }}</h3>
          <p>{{ $t('Disable the “SSL 2.0” option in the browser settings') }}.</p>
          <h3>5. {{ $t('Try disabling antivirus') }}</h3>
          <p>{{ $t('It is likely that an antivirus software protection enabled on your PC') }}.</p>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  name: 'Samruk',
  computed: {
    isSocket () {
      return this.$store.state.socket
    },
    isSocketKey () {
      return this.$store.state.socketKey
    },
    lang () {
      return this.$i18n.locale
    }
  }
}
</script>
